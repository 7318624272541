import gsap from 'gsap';
import $ from '../core/Dom';
import Viewport from '../core/Viewport';
import Revealer from './Revealer';
import Dispatch from '../core/Dispatch';
import { HEADER_HIDDEN, HEADER_VISIBLE, UNLOAD } from './events';

const header = document.getElementById('top');
const menu = document.getElementById('menu');
const main = document.getElementById('main');
const footer = document.getElementById('bottom');

let hasInited = false;
let headerHidden = false;

const getRevealedTweens = () => $('[data-revealed]')
    .get()
    .reduce((carry, el) => {
        const tween = Viewport.visible(el) ? Revealer.tweens.get(el) : null;
        if (!tween) {
            return carry;
        }
        tween.pause(tween.time());
        return carry.concat(tween);
    }, []);

const isKeyCombo = e => e && (e.which === 2 || e.button === 4 || e.metaKey || e.shiftKey);

const clickHandler = e => {

    const { triggerTarget: target } = e;
    const href = target.getAttribute('href');
    const host = window.top.location.host.split(':')[0];

    // Check if the click was eligble for transition
    if (target.hasAttribute('data-no-transition') || href[0] === '#' || target.getAttribute('target') === '_blank' || (/\.\w+$/).test(href) || isKeyCombo(e) || target.hasAttribute('data-filter') || target.hasAttribute('download') || href.indexOf(host) === -1 || href.indexOf('actions/debug') > -1) {
        return true;
    }

    e.preventDefault();

    Dispatch.emit(UNLOAD);

    // Despite its diffuse name, the "transition" cookie is only used to track if the header should persist visually, or be able to use its reveal animation
    if (!headerHidden && window.sessionStorage) {
        //window.vrsg.setCookie('transition', true, 0, '/');
        window.sessionStorage.setItem('persist-header', true);
    }

    // Rewind revealed tweens
    const revealedTweens = getRevealedTweens();

    if (revealedTweens.length) {
        gsap
            .to(revealedTweens, {
                progress: 0,
                duration: 0.35,
                stagger: 0.01,
                ease: 'none'
            });
    }

    gsap.set(header, { opacity: 1 });

    const tl = gsap.timeline()
        // Tween the document background to white
        .set([document.documentElement, document.body], { transition: 'none' }, 0)
        .to([document.documentElement, document.body], {
            backgroundColor: '#FBFBFB',
            duration: 0.29,
            ease: 'Quad.easeIn'
        }, 0);

    if (menu && !menu.hidden) {
        tl
            .set([main, footer], {
                opacity: 0
            }, 0);
    } else {
        tl.to([main, footer], {
            opacity: 0,
            duration: 0.29,
            ease: 'Quad.easeIn'
        }, 0);
    }

    // Redirect
    setTimeout(() => {
        window.location.href = href;
    }, 300);

    return false;
};

const initLinks = () => {
    $('body')
        .on('click', 'a[href]', clickHandler);
};

const onHeaderHidden = () => {
    headerHidden = true;
};

const onHeaderVisible = () => {
    headerHidden = false;
};

const init = () => {

    if (hasInited) {
        return;
    }

    //window.vrsg.deleteCookie('transition');
    if (window.sessionStorage) {
        window.sessionStorage.removeItem('persist-header');
    }

    hasInited = true;

    initLinks();

    // Fix for Safari's back button (back-forward cache)
    window.onpageshow = e => {
        if (e.persisted) {
            setTimeout(() => {
                // Reset revealed tweens
                gsap.set([document.documentElement, document.body], { clearProps: 'backgroundColor' });
                gsap.set([main, footer], { clearProps: 'opacity' });
                const revealedTweens = getRevealedTweens();
                if (revealedTweens.length) {
                    gsap.set(revealedTweens, { progress: 1 });
                }
            }, 0);
        }
    };

    Dispatch.on(HEADER_HIDDEN, onHeaderHidden, true);
    Dispatch.on(HEADER_VISIBLE, onHeaderVisible, true);

};

const destroy = () => {
    if (!hasInited) {
        return;
    }
    hasInited = false;
    $('body').off('click', 'a[href]', clickHandler);
    window.onpageshow = null;
    Dispatch.off(HEADER_HIDDEN, onHeaderHidden);
    Dispatch.off(HEADER_VISIBLE, onHeaderVisible);
};

export default ({
    init,
    destroy
});
